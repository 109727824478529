export default [
    'ID',
    'Name',
    'API 14',
    'OperatorKey',
    'Operator Number',
    'API No',
    'Well Name',
    'Incident',
    'Postal Code',
    'Lease',
    "Well Type",
    "Producing Geological Formation",
    "Surface Managing Entity",
    "Subsurface Managing Entity",
    "Tribe",
    "Pre-Plugging Methane Emissions (grams/hr)",
    "Post-Plugging Methane Emissions (grams/hr)",
    "Methane Measurement Method",
    "Habitat Restored (acres)",
    "Restoration Endpoint",
    "Surface Water Contamination Indicators",
    "Surface Water Remediation Method",
    "Groundwater Contamination Indicators",
    "Groundwater Remediation Method",
    "Community Impact",
    "Community Impact Indicators",
    "Witness Name & Certification",
    "Result",
    "Place Value",
    "Package Name",
    "Plugged Year-Month",
    'Field',
    "Date Restoration Complete",
    "Surface Water Remediation Completion Date",
    "Groundwater Remediation Completion Date",
    "Witness Date",
    "Tribal",
    "UIC Type",
    // "UIC Permit Approval Date",
    "UIC Permit Type",
    "API Number",
    "Well Name and Number",
    "Well Class",
    "Well Status",
    "Operator",
    "Field",
    "Completed Reservoir",
    "Section",
    "Township",
    "Range",
    "Deviation",
    "# of Identified Remedial Actions for UIC Well",
    "# of Completed Remedial Actions for UIC Well",
    "UIC Comments",
    "AOR Well API Number",
    "AOR Well Name",
    "AOR Well Class",
    "In Inj. Zone?",
    "In Inj Zone?",
    "Count of Pending Identified Actions",
    "UIC Well API Number",
    "UIC Well Type",
    "UIC Well Sub-Type",
    "Review Date",
    "UIC Permit Form ID",
    "UIC Permit Operator",
    "Docket Number",
    // "Received Date",
    // "UIC Permit Approval Date",
    "Well Operator",
    "Application Type",
    "Directional Survey",
    "UIC WAR eForm ID",
    "LegacySampleID",
    "ParentSampleID",
    "Award Number",
    "Address1",
    "Address2",
    "Contact Email",
    "State Contract Number",
    "Federal Contract Number",
    "Zip",
    "Laserfiche Number",
    "EProcurement Number",
    "Purchase Order Number"
]